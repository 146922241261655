export const CREATE_PRODUCT_GROUP_CONFIG = {
	screen_composition: {
		components: [
			{
				id: "create-product-group-title-wrapper",
				type: "div",
				staticProps: {
					className: "page-title-container",
					customStyle: {
						marginBottom: "20px",
					},
				},
				componentProps: {
					content: [
						{
							id: "create-product-group-title",
							isDefaultExport: false,
							type: "text",
							staticProps: {
								className: "common-page-title",
								variant: "h2",
								content: "Create Product Group",
							},
						},
						{
							id: "return-to-product-group-button",
							type: "button",
							staticProps: {
								icon: "return",
								iconClass: "",
								className: "",
								customStyle: {
									fontSize: "14px",
									margin: 0,
									fontWeight: 500,
									background: "transparent",
								},
								variant: "secondary",
								label: "Return to Grouping",
								iconType: "svg",
							},
							functionProps: [
								{
									functionName: "onClick",
									actions: [
										{
											type: "redirect",
											link: "product-group-configuration",
										},
									],
								},
							],
						},
					],
				},
			},
			{
				id: "create-product-group-content-wrapper",
				type: "div",
				staticProps: {
					className: "box-container",
				},
				componentProps: {
					content: [
						{
							id: "create-product-group-accordion-1",
							type: "accordion",
							staticProps: {
								label: "Details",
								expanded: true,
								defaultExpanded: true,
								hideCollapse: true,
								hideBlueLine: true,
							},
							componentProps: {
								content: [
									{
										type: "mapped_component",
										id: "ProductGroupDetails",
									},
								],
							},
						},
					],
				},
			},
			{
				id: "create-product-group-divider",
				type: "divider",
				staticProps: {
					margin: "20px 0px",
				},
			},
			{
				id: "create-product-group-selection-wrapper",
				type: "div",
				staticProps: {
					className: "box-container",
					customStyle: {
						paddingLeft: "30px",
					},
				},
				componentProps: {
					content: [
						{
							id: "create-product-group-accordion-2",
							type: "accordion",
							staticProps: {
								className: "",
								label: "Product Selection",
								expanded: true,
								defaultExpanded: true,
							},
							componentProps: {
								content: [
									{
										type: "mapped_component",
										id: "ProductGroupSelection",
									},
								],
							},
						},
						{
							id: "edit-product-group-confirmation-modal-filters",
							type: "modal",
							staticProps: {
								className: "",
								modalTitle: "Product Selection",
								modalContent:
									"Would you like to retain the original product selection?",
								primaryButtonText: "Retain",
								secondaryButtonText: "Replace and Overwrite",
								modalSize: "medium",
							},
							functionProps: [
								{
									functionName: "onConfirm",
									actions: [
										{
											type: "api_function",
											apiEndPoint: "products",
											apiMethod: "post",
											params: [
												{
													source: "filters",
													dataType: "object",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "filteredProducts",
													apiResponseKey: "products",
													dataType: "array",
												},
												{
													destination: "reducer",
													dataKey: "products_count",
													apiResponseKey:
														"products_count",
													overwrite: true,
													dataType: "array",
												},
											],
										},
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showEditGroupConfirmationForFilters",
													dataType: "basic",
													value: false,
													overwrite: true,
												},
											],
										},
									],
								},
								{
									functionName: "onDeny",
									actions: [
										{
											type: "api_function",
											apiEndPoint: "products",
											apiMethod: "post",
											params: [
												{
													source: "filters",
													dataType: "object",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "filteredProducts",
													apiResponseKey: "products",
													overwrite: true,
													dataType: "array",
												},
												{
													destination: "reducer",
													dataKey: "products_count",
													apiResponseKey:
														"products_count",
													overwrite: true,
													dataType: "array",
												},
											],
										},
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showEditGroupConfirmationForFilters",
													dataType: "basic",
													value: false,
													overwrite: true,
												},
											],
										},
									],
								},
								{
									functionName: "onClose",
									actions: [
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showEditGroupConfirmationForFilters",
													dataType: "basic",
													value: false,
													overwrite: true,
												},
											],
										},
									],
								},
							],
							dataProps: {
								showModal: {
									type: "derived",
									dataKey:
										"showEditGroupConfirmationForFilters",
								},
							},
						},
						{
							id: "edit-product-group-confirmation-modal-upload",
							type: "modal",
							staticProps: {
								className: "",
								modalTitle: "Product Selection",
								modalContent:
									"Would you like to retain the original product selection?",
								primaryButtonText: "Retain",
								secondaryButtonText: "Replace and Overwrite",
								modalSize: "medium",
							},
							functionProps: [
								{
									functionName: "onConfirm",
									actions: [
										{
											type: "api_function",
											apiEndPoint:
												"get-products-from-file",
											apiMethod: "post",
											params: [
												{
													source: "reducer",
													sourceId: "uploadData",
													apiRequestKey: "file",
													dataType: "file",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"sku_details_inactive",
													dataType: "array",
													apiResponseKey: "inactive",
													overwrite: true,
												},
												{
													apiResponseKey: "invalid",
													destination: "reducer",
													dataKey:
														"sku_details_invalid",
													dataType: "array",
													overwrite: true,
												},
												{
													apiResponseKey: "valid",
													destination: "reducer",
													dataKey:
														"sku_details_valid",
													dataType: "array",
													overwrite: true,
												},
												{
													apiResponseKey: "valid",
													dataKey: "filteredProducts",
													destination: "table",
													dataType: "array",
													selectAll: false,
												},
											],
											onComplete: {
												actions: [
													{
														type: "validation",
														conditions: [
															{
																comparison:
																	"exists",
																source: "response",
																sourceId:
																	"invalid",
															},
															{
																comparison:
																	"exists",
																source: "response",
																sourceId:
																	"inactive",
															},
														],
														conditionOperator: "or",
														onValidationSuccess: {
															actions: [
																{
																	type: "reducer_function",
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"showValidationModalForUploadAppend",
																				dataType:
																					"basic",
																				value: true,
																				overwrite: true,
																			},
																		],
																},
															],
														},
													},
												],
											},
										},
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showEditGroupConfirmationForUpload",
													dataType: "basic",
													value: false,
													overwrite: true,
												},
											],
										},
									],
								},
								{
									functionName: "onDeny",
									actions: [
										{
											type: "api_function",
											apiEndPoint:
												"get-products-from-file",
											apiMethod: "post",
											params: [
												{
													source: "reducer",
													sourceId: "uploadData",
													apiRequestKey: "file",
													dataType: "file",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"sku_details_inactive",
													overwrite: true,
													dataType: "array",
													apiResponseKey: "inactive",
												},
												{
													apiResponseKey: "invalid",
													destination: "reducer",
													dataKey:
														"sku_details_invalid",
													overwrite: true,
													dataType: "array",
												},
												{
													apiResponseKey: "valid",
													destination: "reducer",
													dataKey:
														"sku_details_valid",
													dataType: "array",
													overwrite: true,
												},
												{
													apiResponseKey: "valid",
													dataKey: "filteredProducts",
													destination: "table",
													dataType: "array",
													selectAll: false,
													overwrite: true,
												},
											],
											onComplete: {
												actions: [
													{
														type: "validation",
														conditions: [
															{
																comparison:
																	"exists",
																source: "response",
																sourceId:
																	"invalid",
															},
															{
																comparison:
																	"exists",
																source: "response",
																sourceId:
																	"inactive",
															},
														],
														conditionOperator: "or",
														onValidationSuccess: {
															actions: [
																{
																	type: "reducer_function",
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"showValidationModalForUploadOverwrite",
																				dataType:
																					"basic",
																				value: true,
																				overwrite: true,
																			},
																		],
																},
															],
														},
													},
												],
											},
										},
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showEditGroupConfirmationForUpload",
													dataType: "basic",
													value: false,
													overwrite: true,
												},
											],
										},
									],
								},
								{
									functionName: "onClose",
									actions: [
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showEditGroupConfirmationForUpload",
													dataType: "basic",
													value: false,
													overwrite: true,
												},
											],
										},
									],
								},
							],
							dataProps: {
								showModal: {
									type: "derived",
									dataKey:
										"showEditGroupConfirmationForUpload",
								},
							},
						},
						{
							id: "edit-product-group-confirmation-modal-copypaste",
							type: "modal",
							staticProps: {
								className: "",
								modalTitle: "Product Selection",
								modalContent:
									"Would you like to retain the original product selection?",
								primaryButtonText: "Retain",
								secondaryButtonText: "Replace and Overwrite",
								modalSize: "medium",
							},
							functionProps: [
								{
									functionName: "onConfirm",
									actions: [
										{
											type: "api_function",
											apiEndPoint: "sku-details",
											apiMethod: "post",
											params: [
												{
													source: "reducer",
													sourceId: "copyPasteData",
													apiRequestKey:
														"product_h5_id",
													dataType: "array",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"sku_details_inactive",
													dataType: "array",
													apiResponseKey: "inactive",
													overwrite: true,
												},
												{
													apiResponseKey: "invalid",
													destination: "reducer",
													dataKey:
														"sku_details_invalid",
													dataType: "array",
													overwrite: true,
												},
												{
													apiResponseKey: "valid",
													destination: "reducer",
													dataKey:
														"sku_details_valid",
													dataType: "array",
													overwrite: true,
												},
												{
													apiResponseKey: "valid",
													dataKey: "filteredProducts",
													destination: "table",
													dataType: "array",
													selectAll: false,
												},
											],
											onComplete: {
												actions: [
													{
														type: "validation",
														conditions: [
															{
																comparison:
																	"exists",
																source: "response",
																sourceId:
																	"invalid",
															},
															{
																comparison:
																	"exists",
																source: "response",
																sourceId:
																	"inactive",
															},
														],
														conditionOperator: "or",
														onValidationSuccess: {
															actions: [
																{
																	type: "reducer_function",
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"showValidationModalForUploadAppend",
																				dataType:
																					"basic",
																				value: true,
																				overwrite: true,
																			},
																		],
																},
															],
														},
													},
												],
											},
										},
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showEditGroupConfirmationForCopyPaste",
													dataType: "basic",
													value: false,
													overwrite: true,
												},
											],
										},
									],
								},
								{
									functionName: "onDeny",
									actions: [
										{
											type: "api_function",
											apiEndPoint: "sku-details",
											apiMethod: "post",
											params: [
												{
													source: "reducer",
													sourceId: "copyPasteData",
													apiRequestKey:
														"product_h5_id",
													dataType: "array",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"sku_details_inactive",
													overwrite: true,
													dataType: "array",
													apiResponseKey: "inactive",
												},
												{
													apiResponseKey: "invalid",
													destination: "reducer",
													dataKey:
														"sku_details_invalid",
													overwrite: true,
													dataType: "array",
												},
												{
													apiResponseKey: "valid",
													destination: "reducer",
													dataKey:
														"sku_details_valid",
													dataType: "array",
													overwrite: true,
												},
												{
													apiResponseKey: "valid",
													dataKey: "filteredProducts",
													destination: "table",
													dataType: "array",
													selectAll: false,
													overwrite: true,
												},
											],
											onComplete: {
												actions: [
													{
														type: "validation",
														conditions: [
															{
																comparison:
																	"exists",
																source: "response",
																sourceId:
																	"invalid",
															},
															{
																comparison:
																	"exists",
																source: "response",
																sourceId:
																	"inactive",
															},
														],
														conditionOperator: "or",
														onValidationSuccess: {
															actions: [
																{
																	type: "reducer_function",
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"showValidationModalForUploadOverwrite",
																				dataType:
																					"basic",
																				value: true,
																				overwrite: true,
																			},
																		],
																},
															],
														},
													},
												],
											},
										},
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showEditGroupConfirmationForCopyPaste",
													dataType: "basic",
													value: false,
													overwrite: true,
												},
											],
										},
									],
								},
								{
									functionName: "onClose",
									actions: [
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showEditGroupConfirmationForCopyPaste",
													dataType: "basic",
													value: false,
													overwrite: true,
												},
											],
										},
									],
								},
							],
							dataProps: {
								showModal: {
									type: "derived",
									dataKey:
										"showEditGroupConfirmationForCopyPaste",
								},
							},
						},
						{
							id: "create-product-group-upload-sku-validation-modal-overwrite",
							type: "modal",
							staticProps: {
								className: "",
								modalTitle: "SKU Details",
								primaryButtonText:
									"Proceed with Only Active SKUs",
								secondaryButtonText:
									"Proceed with all Active and Inactive SKUs",
								modalSize: "large",
							},
							componentProps: {
								content: [
									{
										id: "",
										isDefaultExport: false,
										type: "text",
										staticProps: {
											className: "",
											variant: "paragraph",
											content:
												"Based on the data feed Pricesmart received, the uploaded list contains some inactive/invalid SKUs",
										},
									},
									{
										id: "filtered_products_validation_error",
										type: "aggrid",
										staticProps: {
											height: "300px",
											tableId:
												"filtered_products_validation_error",
											tableTitle: "",
											uniqueKey: "product_h5_id",
											tableConfig: {
												column_headers: [
													{
														type: "static_column",
														mapping: "data",
														header: "SKU Id",
														key: "product_h5_id",
														action: null,
														hidden: false,
														aggregate: null,
														sorting: true,
														filter: true,
														pinned: false,
														row_group: false,
														flex: 1,
													},
													{
														type: "static_column",
														mapping: "data",
														header: "Status",
														key: "status",
														action: null,
														hidden: false,
														aggregate: null,
														sorting: true,
														filter: true,
														pinned: false,
														row_group: false,
														flex: 2,
													},
												],
											},
										},
										dataProps: {
											data: {
												type: "derived",
												dataKey: [
													"sku_details_inactive",
													"sku_details_invalid",
												],
												dataType: "array",
											},
										},
										componentProps: {},
									},
								],
							},
							functionProps: [
								{
									functionName: "onConfirm",
									actions: [
										{
											type: "reducer_function",
											params: [
												{
													source: "reducer",
													sourceId:
														"sku_details_valid",
													dataType: "array",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "filteredProducts",
													dataType: "array",
													overwrite: true,
												},
											],
										},
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showValidationModalForUploadOverwrite",
													dataType: "basic",
													value: false,
													overwrite: true,
												},
											],
										},
									],
								},
								{
									functionName: "onDeny",
									actions: [
										{
											type: "reducer_function",
											params: [
												{
													source: "reducer",
													sourceId:
														"sku_details_inactive",
													dataType: "array",
												},
												{
													source: "reducer",
													sourceId:
														"sku_details_valid",
													dataType: "array",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "filteredProducts",
													overwrite: true,
													dataType: "array",
												},
											],
										},
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showValidationModalForUploadOverwrite",
													dataType: "basic",
													value: false,
													overwrite: true,
												},
											],
										},
									],
								},
								{
									functionName: "onClose",
									actions: [
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showValidationModalForUploadOverwrite",
													dataType: "basic",
													value: false,
													overwrite: true,
												},
											],
										},
									],
								},
							],
							dataProps: {
								showModal: {
									type: "derived",
									dataKey:
										"showValidationModalForUploadOverwrite",
								},
							},
						},
						{
							id: "create-product-group-upload-sku-validation-modal-append",
							type: "modal",
							staticProps: {
								className: "",
								modalTitle: "SKU Details",
								primaryButtonText:
									"Proceed with Only Active SKUs",
								secondaryButtonText:
									"Proceed with all Active and Inactive SKUs",
								modalSize: "large",
							},
							componentProps: {
								content: [
									{
										id: "",
										isDefaultExport: false,
										type: "text",
										staticProps: {
											className: "",
											variant: "paragraph",
											content:
												"Based on the data feed Pricesmart received, the uploaded list contains some inactive/invalid SKUs",
										},
									},
									{
										id: "filtered_products_validation_error",
										type: "aggrid",
										staticProps: {
											height: "300px",
											tableId:
												"filtered_products_validation_error",
											tableTitle: "SKU Details",
											uniqueKey: "product_h5_id",
											tableConfig: {
												column_headers: [
													{
														type: "static_column",
														mapping: "data",
														header: "SKU Id",
														key: "product_h5_id",
														action: null,
														hidden: false,
														aggregate: null,
														sorting: true,
														filter: true,
														pinned: false,
														row_group: false,
														flex: 1,
													},
													{
														type: "static_column",
														mapping: "data",
														header: "Status",
														key: "status",
														action: null,
														hidden: false,
														aggregate: null,
														sorting: true,
														filter: true,
														pinned: false,
														row_group: false,
														flex: 2,
													},
												],
											},
										},
										dataProps: {
											data: {
												type: "derived",
												dataKey: [
													"sku_details_inactive",
													"sku_details_invalid",
												],
												dataType: "array",
											},
										},
										componentProps: {},
									},
								],
							},
							functionProps: [
								{
									functionName: "onConfirm",
									actions: [
										{
											type: "reducer_function",
											params: [
												{
													source: "reducer",
													sourceId:
														"sku_details_valid",
													dataType: "array",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "filteredProducts",
													dataType: "array",
												},
											],
										},
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showValidationModalForUploadAppend",
													dataType: "basic",
													value: false,
													overwrite: true,
												},
											],
										},
									],
								},
								{
									functionName: "onDeny",
									actions: [
										{
											type: "reducer_function",
											params: [
												{
													source: "reducer",
													sourceId:
														"sku_details_inactive",
													dataType: "array",
												},
												{
													source: "reducer",
													sourceId:
														"sku_details_valid",
													dataType: "array",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "filteredProducts",
													dataType: "array",
												},
											],
										},
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showValidationModalForUploadAppend",
													dataType: "basic",
													value: false,
													overwrite: true,
												},
											],
										},
									],
								},
								{
									functionName: "onClose",
									actions: [
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showValidationModalForUploadAppend",
													dataType: "basic",
													value: false,
													overwrite: true,
												},
											],
										},
									],
								},
							],
							dataProps: {
								showModal: {
									type: "derived",
									dataKey:
										"showValidationModalForUploadAppend",
								},
							},
						},
					],
				},
			},
			{
				id: "create-product-group-submit-wrapper",
				type: "div",
				staticProps: {
					className: "flex-container",
				},
				componentProps: {
					content: [
						{
							id: "create-product-group-submit-button",
							type: "button",
							staticProps: {
								variant: "primary",
								label: "Submit and Create Product Group",
								className: "create-product-group-button",
								display: {
									type: "controlled",
									default: {
										show: true,
										enabled: true,
									},
								},
							},
							functionProps: [
								{
									functionName: "onClick",
									actions: [
										{
											type: "api_function",
											apiEndPoint: "product-group",
											apiMethod: "post",
											confirmation: {
												type: "check",
												showErrorMessage: true,
												conditions: [
													{
														comparison: "exists",
														source: "reducer",
														sourceId:
															"create_product_group_details",
														subjectId:
															"product_group_name",
														dataType: "basic",
														message:
															"Please enter the product group name",
													},
													{
														comparison: "exists",
														source: "reducer",
														sourceId:
															"create_product_group_details",
														subjectId:
															"product_group_description",
														dataType: "basic",
														message:
															"Please enter the product group description",
													},
													{
														comparison: "exists",
														source: "table",
														sourceId:
															"create_product_group",
														message:
															"Please select products from the Products table",
													},
												],
												conditionOperator: "and",
											},
											params: [
												{
													source: "table",
													sourceId:
														"create_product_group",
													apiRequestKey:
														"product_h5_id",
													dataType: "array",
												},
												{
													source: "reducer",
													sourceId:
														"create_product_group_details",
													subjectId:
														"product_group_name",
													apiRequestKey:
														"product_group_name",
													dataType: "object",
												},
												{
													source: "reducer",
													sourceId:
														"create_product_group_details",
													subjectId:
														"product_group_description",
													apiRequestKey:
														"product_group_description",
													dataType: "object",
												},
											],
											responseFormatter: [],
											onComplete: {
												actions: [
													// Populate filters for product group listing
													// Redirect
													{
														type: "redirect",
														link: "product-group-configuration",
													},
												],
											},
										},
									],
								},
							],
						},
						{
							id: "edit-product-group-submit-button",
							type: "button",
							staticProps: {
								variant: "primary",
								label: "Submit and Edit Product Group",
								className: "create-product-group-button",
								display: {
									type: "controlled",
									default: {
										show: false,
										enabled: true,
									},
								},
							},
							functionProps: [
								{
									functionName: "onClick",
									actions: [
										{
											type: "api_function",
											apiEndPoint: "model",
											apiMethod: "post",
											confirmation: {
												type: "check",
												showErrorMessage: true,
												conditions: [
													{
														comparison: "exists",
														source: "reducer",
														sourceId:
															"create_product_group_details",
														subjectId:
															"product_group_name",
														dataType: "basic",
														message:
															"Please enter the product group name",
													},
													{
														comparison: "exists",
														source: "reducer",
														sourceId:
															"create_product_group_details",
														subjectId:
															"product_group_description",
														dataType: "basic",
														message:
															"Please enter the product group description",
													},
													{
														comparison: "exists",
														source: "table",
														sourceId:
															"create_product_group",
														message:
															"Please select products from the Products table",
													},
												],
												conditionOperator: "and",
											},
											params: [
												{
													source: "fixed",
													dataType: "object",
													apiRequestKey: "id",
													value: 1000,
												},
												{
													source: "reducer",
													sourceId:
														"create_product_group_details",
													subjectId:
														"product_group_id",
													apiRequestKey: "parameters",
													apiRequestKeyNested:
														"product_group_id",
													dataType: "object",
												},
												{
													source: "table",
													sourceId:
														"create_product_group",
													apiRequestKey: "parameters",
													apiRequestKeyNested:
														"product_h5_id",
													subjectId: "product_h5_id",
													dataType: "array",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"edit_products_effected_strategy_data",
													dataType: "array",
													overwrite: true,
												},
											],
											onComplete: {
												actions: [
													{
														type: "validation",
														conditions: [
															{
																comparison:
																	"notExists",
																source: "response",
															},
														],
														conditionOperator: "or",
														onValidationSuccess: {
															actions: [
																{
																	type: "api_function",
																	apiEndPoint:
																		"product-group",
																	apiMethod:
																		"put",
																	params: [
																		{
																			source: "table",
																			sourceId:
																				"create_product_group",
																			apiRequestKey:
																				"product_h5_id",
																			dataType:
																				"array",
																		},
																		{
																			source: "reducer",
																			sourceId:
																				"create_product_group_details",
																			subjectId:
																				"product_group_id",
																			apiRequestKey:
																				"product_group_id",
																			dataType:
																				"object",
																		},
																		{
																			source: "reducer",
																			sourceId:
																				"create_product_group_details",
																			subjectId:
																				"product_group_name",
																			apiRequestKey:
																				"product_group_name",
																			dataType:
																				"object",
																		},
																		{
																			source: "reducer",
																			sourceId:
																				"create_product_group_details",
																			subjectId:
																				"product_group_description",
																			apiRequestKey:
																				"product_group_description",
																			dataType:
																				"object",
																		},
																	],
																	onComplete:
																		{
																			actions:
																				[
																					// Populate filters for product group listing
																					// Redirect
																					{
																						type: "redirect",
																						link: "product-group-configuration",
																					},
																				],
																		},
																},
															],
														},
													},
													{
														type: "validation",
														conditions: [
															{
																comparison:
																	"exists",
																source: "response",
															},
														],
														conditionOperator: "or",
														onValidationSuccess: {
															actions: [
																{
																	type: "reducer_function",
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"show_edit_products_alert_modal",
																				dataType:
																					"basic",
																				value: true,
																				overwrite: true,
																			},
																		],
																},
															],
														},
													},
												],
											},
										},
									],
								},
							],
						},
					],
				},
			},
			{
				id: "edit_product_alert",
				type: "modal",
				staticProps: {
					className: "",
					modalTitle: "Are you sure?",
					modalContent:
						"The following strategies will be affected by this change. Would you like to proceed?",
					primaryButtonText: "Proceed",
					secondaryButtonText: "Cancel",
					modalSize: "medium",
				},
				dataProps: {
					showModal: {
						type: "derived",
						dataKey: "show_edit_products_alert_modal",
					},
				},
				componentProps: {
					content: [
						{
							id: "edit_products_effected_strategy_table",
							type: "aggrid",
							staticProps: {
								height: "300px",
								tableId:
									"edit_products_effected_strategy_table",
								uniqueKey: "strategy_id",
								tableConfig: {
									column_headers: [
										{
											type: "static_column",
											mapping: "data",
											header: "",
											key: "strategy_id",
											action: null,
											hidden: true,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											flex: 1,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Strategy",
											key: "strategy_name",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											flex: 2,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Start Date",
											key: "start_date",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											flex: 2,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "End Date",
											key: "end_date",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											flex: 2,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Status",
											key: "status",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											flex: 2,
										},
									],
								},
							},
							dataProps: {
								data: {
									type: "derived",
									dataKey:
										"edit_products_effected_strategy_data",
								},
							},
						},
					],
				},
				functionProps: [
					{
						functionName: "onConfirm",
						actions: [
							{
								type: "api_function",
								apiEndPoint: "product-group",
								apiMethod: "put",
								params: [
									{
										source: "table",
										sourceId: "create_product_group",
										apiRequestKey: "product_h5_id",
										dataType: "array",
									},
									{
										source: "reducer",
										sourceId:
											"create_product_group_details",
										subjectId: "product_group_id",
										apiRequestKey: "product_group_id",
										dataType: "object",
									},
									{
										source: "reducer",
										sourceId:
											"create_product_group_details",
										subjectId: "product_group_name",
										apiRequestKey: "product_group_name",
										dataType: "object",
									},
									{
										source: "reducer",
										sourceId:
											"create_product_group_details",
										subjectId: "product_group_description",
										apiRequestKey:
											"product_group_description",
										dataType: "object",
									},
								],
								onComplete: {
									actions: [
										// Populate filters for product group listing
										// Redirect
										{
											type: "redirect",
											link: "product-group-configuration",
										},
									],
								},
							},
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey:
											"show_edit_products_alert_modal",
										value: false,
										overwrite: true,
									},
								],
							},
						],
					},
					{
						functionName: "onClose",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey:
											"show_edit_products_alert_modal",
										value: false,
										overwrite: true,
									},
								],
							},
						],
					},
				],
			},
		],
	},
	on_load_functions: [
		{
			type: "api_function",
			apiEndPoint: "product-group",
			apiMethod: "get",
			on_load_confirmation: {
				type: "check",
				conditions: [
					{
						comparison: "exists",
						source: "reducer",
						sourceId: "create_product_group_details",
						subjectId: "product_group_id",
						dataType: "basic",
					},
				],
			},
			params: [
				{
					source: "reducer",
					sourceId: "create_product_group_details",
					subjectId: "product_group_id",
					apiRequestKey: "product_group_id",
					dataType: "basic",
				},
			],
			responseFormatter: [
				{
					subjectId: "product_group_name",
					apiResponseKey: "product_group_name",
					dataType: "basic",
					destination: "reducer",
					dataKey: "create_product_group_details",
				},
				{
					subjectId: "product_group_description",
					apiResponseKey: "product_group_description",
					dataType: "basic",
					destination: "reducer",
					dataKey: "create_product_group_details",
				},
				{
					apiResponseKey: "sku_details",
					dataType: "array",
					dataKey: "filteredProducts",
					overwrite: true,
					destination: "table",
					selectAll: true,
				},
				{
					destination: "reducer",
					dataKey: "productGroupDetail",
					overwrite: true,
				},
			],
		},
		{
			type: "reducer_function",
			on_load_confirmation: {
				type: "check",
				conditions: [
					{
						comparison: "exists",
						source: "reducer",
						sourceId: "create_product_group_details",
						subjectId: "product_group_id",
						dataType: "basic",
					},
				],
			},
			params: [],
			responseFormatter: [
				{
					destination: "reducer",
					dataType: "object",
					dataKey: "componentDisplayControls",
					subjectId: "create-product-group-submit-button",
					value: {
						show: false,
						enabled: false,
					},
				},
				{
					destination: "reducer",
					dataType: "object",
					dataKey: "componentDisplayControls",
					subjectId: "edit-product-group-submit-button",
					value: {
						show: true,
						enabled: true,
					},
				},
			],
		},
	],
	mapped_components: {
		CreateProductGroupFilters: {
			screen_composition: {
				components: [
					{
						id: "",
						type: "div",
						staticProps: {
							className: "product-config-filter-wrapper",
						},
						componentProps: {
							content: [
								{
									id: "",
									type: "filter",
									staticProps: {
										showSaveFilter: false,
										showPageHeader: false,
										showFilterSelection: true,
										accordionLabel:
											"Merch Hierarchy Selection",
										showFilterButtons: true,
										hideCollapse: true,
										hideBlueLine: true,
										ignoreSavedFilters: true,
										filter_config: [
											{
												type: "product_h1",
												is_mandatory: true,
												is_multiple_selection: true,
												filter_endpoint: "filters",
												filter_type:
													"product_hierarchy",
												filter_group: 1,
												hideSearch: false,
											},
											{
												type: "product_h2",
												is_mandatory: true,
												is_multiple_selection: true,
												filter_endpoint: "filters",
												filter_type:
													"product_hierarchy",
												filter_group: 1,
												hideSearch: false,
											},
											{
												type: "product_h3",
												is_mandatory: false,
												is_multiple_selection: true,
												filter_endpoint: "filters",
												filter_type:
													"product_hierarchy",
												filter_group: 1,
												hideSearch: false,
											},
											{
												type: "product_h4",
												is_mandatory: false,
												is_multiple_selection: true,
												filter_endpoint: "filters",
												filter_type:
													"product_hierarchy",
												filter_group: 1,
												hideSearch: false,
											},
											{
												type: "brand",
												is_mandatory: false,
												is_multiple_selection: true,
												filter_endpoint: "filters",
												filter_type:
													"product_hierarchy",
												filter_group: 1,
												hideSearch: false,
											},
										],
									},
									functionProps: [
										{
											functionName: "onFilter",
											actions: [
												{
													type: "api_function",
													apiEndPoint: "products",
													apiMethod: "post",
													params: [
														{
															source: "filters",
															dataType: "object",
														},
													],
													responseFormatter: [
														{
															destination:
																"reducer",
															dataKey:
																"filteredProducts",
															apiResponseKey:
																"products",
															overwrite: true,
															dataType: "array",
														},
														{
															destination:
																"reducer",
															dataKey:
																"products_count",
															apiResponseKey:
																"products_count",
															overwrite: true,
															dataType: "array",
														},
													],
													confirmation: {
														type: "modal",
														modalDataKey:
															"showEditGroupConfirmationForFilters",
														conditions: [
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"filteredProducts",
															},
														],
														conditionOperator:
															"and",
													},
												},
											],
										},
									],
								},
							],
						},
					},
				],
			},
		},
		CreateProductGroupUpload: {
			screen_composition: {
				components: [
					{
						id: "",
						isDefaultExport: false,
						type: "text",
						staticProps: {
							className: "no-margin",
							variant: "paragraph",
							content:
								"Please upload your excel sheet to get the data in the table",
						},
					},
					{
						id: "",
						type: "dropzone",
						staticProps: {
							showDownloadTemplate: true,
							excelTemplateData: [{ ["SKU ID"]: "SKU 123" }],
							csvTemplateData: [{ ["SKU ID"]: "SKU 123" }],
							csvDelimiter: ";",
							templateFileName: "create_product_group_template",
						},
						functionProps: [
							{
								functionName: "setUploadFile",
								actions: [
									{
										type: "api_function",
										apiEndPoint: "get-products-from-file",
										apiMethod: "post",
										params: [
											{
												source: "self",
												apiRequestKey: "file",
												dataType: "file",
											},
										],
										responseFormatter: [
											{
												destination: "reducer",
												dataKey: "sku_details_inactive",
												dataType: "array",
												apiResponseKey: "inactive",
												overwrite: true,
											},
											{
												apiResponseKey: "invalid",
												destination: "reducer",
												dataKey: "sku_details_invalid",
												dataType: "array",
												overwrite: true,
											},
											{
												apiResponseKey: "valid",
												destination: "reducer",
												dataKey: "sku_details_valid",
												dataType: "array",
												overwrite: true,
											},
											{
												apiResponseKey: "valid",
												dataKey: "filteredProducts",
												destination: "table",
												dataType: "array",
												selectAll: false,
												overwrite: true,
											},
										],
										onComplete: {
											actions: [
												{
													type: "validation",
													conditions: [
														{
															comparison:
																"exists",
															source: "response",
															sourceId: "invalid",
														},
														{
															comparison:
																"exists",
															source: "response",
															sourceId:
																"inactive",
														},
													],
													conditionOperator: "or",
													onValidationSuccess: {
														actions: [
															{
																type: "reducer_function",
																responseFormatter:
																	[
																		{
																			destination:
																				"reducer",
																			dataKey:
																				"showValidationModalForUploadOverwrite",
																			dataType:
																				"basic",
																			value: true,
																			overwrite: true,
																		},
																	],
															},
														],
													},
												},
											],
										},
										confirmation: {
											type: "modal",
											modalDataKey:
												"showEditGroupConfirmationForUpload",
											temporaryDataStorageKey:
												"uploadData",
											conditionOperator: "and",
											conditions: [
												{
													comparison: "exists",
													source: "reducer",
													sourceId:
														"filteredProducts",
												},
											],
										},
									},
								],
							},
						],
						dataProps: {
							sku_details_inactive: {
								type: "derived",
								dataKey: "sku_details_inactive",
							},
							sku_details_invalid: {
								type: "derived",
								dataKey: "sku_details_invalid",
							},
						},
					},
				],
			},
		},
		CreateProductGroupCopyPaste: {
			screen_composition: {
				components: [
					{
						id: "",
						isDefaultExport: false,
						type: "text",
						staticProps: {
							className: "no-margin",
							variant: "paragraph",
							content:
								"Please paste your data in the text area below",
						},
					},
					{
						id: "",
						type: "copypaster",
						staticProps: {},
						functionProps: [
							{
								functionName: "onCopyPasteComplete",
								actions: [
									{
										type: "api_function",
										apiEndPoint: "sku-details",
										apiMethod: "post",
										params: [
											{
												source: "self",
												apiRequestKey: "product_h5_id",
												dataType: "array",
											},
										],
										responseFormatter: [
											{
												destination: "reducer",
												dataKey: "sku_details_inactive",
												dataType: "array",
												apiResponseKey: "inactive",
												overwrite: true,
											},
											{
												apiResponseKey: "invalid",
												destination: "reducer",
												dataKey: "sku_details_invalid",
												dataType: "array",
												overwrite: true,
											},
											{
												apiResponseKey: "valid",
												destination: "reducer",
												dataKey: "sku_details_valid",
												dataType: "array",
												overwrite: true,
											},
											{
												apiResponseKey: "valid",
												dataKey: "filteredProducts",
												destination: "table",
												dataType: "array",
												selectAll: false,
												overwrite: true,
											},
										],
										onComplete: {
											actions: [
												{
													type: "validation",
													conditions: [
														{
															comparison:
																"exists",
															source: "response",
															sourceId: "invalid",
														},
														{
															comparison:
																"exists",
															source: "response",
															sourceId:
																"inactive",
														},
													],
													conditionOperator: "or",
													onValidationSuccess: {
														actions: [
															{
																type: "reducer_function",
																responseFormatter:
																	[
																		{
																			destination:
																				"reducer",
																			dataKey:
																				"showValidationModalForUploadOverwrite",
																			dataType:
																				"basic",
																			value: true,
																			overwrite: true,
																		},
																	],
															},
														],
													},
												},
											],
										},
										confirmation: {
											type: "modal",
											modalDataKey:
												"showEditGroupConfirmationForCopyPaste",
											temporaryDataStorageKey:
												"copyPasteData",
											conditionOperator: "and",
											conditions: [
												{
													comparison: "exists",
													source: "reducer",
													sourceId:
														"filteredProducts",
												},
											],
										},
									},
								],
							},
						],
						dataProps: {
							sku_details_inactive: {
								type: "derived",
								dataKey: "sku_details_inactive",
							},
							sku_details_invalid: {
								type: "derived",
								dataKey: "sku_details_invalid",
							},
						},
						componentProps: {
							content: [
								{
									id: "filtered_products_validation_error",
									type: "aggrid",
									staticProps: {
										height: "300px",
										tableId:
											"filtered_products_validation_error",
										tableTitle: "Product Table",
										tableConfig: {
											column_headers: [
												{
													type: "static_column",
													mapping: "data",
													header: "SKU Id",
													key: "product_h5_id",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
													flex: 1,
												},
											],
										},
									},
									dataProps: {
										data: {
											type: "derived",
											dataKey: "filteredProducts",
										},
									},
									componentProps: {},
								},
							],
						},
					},
				],
			},
		},
		ProductGroupSelection: {
			screen_composition: {
				components: [
					{
						id: "",
						type: "text",
						staticProps: {
							className: "common-page-text no-margin",
							variant: "paragraph",
							content:
								"Please select one of the methods for uploading data to get results",
						},
					},
					{
						id: "",
						type: "buttonGroup",
						staticProps: {
							variant: "outlined",
							className: "",
							buttons: [
								{
									id: 0,
									label: "Choose Filters",
								},
								{
									id: 1,
									label: "Upload XL",
								},
								{
									id: 2,
									label: "Copy and Paste Data",
								},
							],
							defaultActiveButtonId: 0,
						},
						componentProps: {
							content: [
								{
									type: "mapped_component",
									id: "CreateProductGroupFilters",
									staticProps: {
										buttonGroupContentId: 0,
									},
								},
								{
									type: "mapped_component",
									id: "CreateProductGroupUpload",
									staticProps: {
										buttonGroupContentId: 1,
									},
								},
								{
									type: "mapped_component",
									id: "CreateProductGroupCopyPaste",
									staticProps: {
										buttonGroupContentId: 2,
									},
								},
							],
						},
					},
					{
						id: "",
						type: "divider",
						staticProps: {
							margin: "20px 0px",
						},
					},
					{
						id: "create_product_group",
						type: "aggrid",
						staticProps: {
							height: "400px",
							tableId: "create_product_group",
							rowSelection: "multiple",
							tableTitle: "Product Table",
							selectedRowCountId: "create_product_group_count",
							uniqueKey: "product_h5_id",
							tableConfig: {
								column_headers: [
									{
										type: "static_column",
										mapping: "data",
										header: "",
										key: "",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: false,
										filter: false,
										pinned: true,
										lockPinned: true,
										checkbox_selection: true,
										row_group: false,
										suppressMenu: true,
										maxWidth: 50,
										cellStyle: {
											background: "#f7f7f7",
										},
									},
									{
										type: "static_column",
										mapping: "data",
										header: "SKU Id",
										key: "product_h5_id",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Active",
										key: "is_active",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Web Enabled",
										key: "web_designation_cd",
										action: "convert_boolean",
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "1-1 Replacement",
										key: "store_pickup_eligible_ind",
										action: "convert_boolean",
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Inventory OH",
										key: "inventory",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "DAM",
										key: "image_exists",
										action: "convert_boolean",
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Description",
										key: "product_h5_name",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Base Price",
										key: "price",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										suppressMenu: false,
									},
									{
										type: "static_column",
										mapping: "data",
										header: "Comp Value",
										key: "comp_value",
										action: null,
										hidden: false,
										aggregate: null,
										sorting: true,
										filter: true,
										pinned: false,
										row_group: false,
										flex: 1,
										minWidth: 250,
									},
								],
							},
						},
						dataProps: {
							data: {
								type: "derived",
								dataKey: "filteredProducts",
							},
						},
						componentProps: {
							content: [
								{
									alignment: "right",
									type: "text",
									staticProps: {
										variant: "h5",
										customStyle: {
											margin: 0,
											display: "flex",
											gap: "5px",
										},
									},
									dataProps: {
										dynamicContent: {
											type: "derived",
											dataKey: "tableData",
											sourceId: "create_strategy_store",
											textContent: [
												{
													content: "Showing ",
													type: "static",
												},
												{
													content:
														"create_product_group_count",
													type: "dynamic",
													className: "bold",
													default: "0",
												},
												{
													content:
														"selected products",
													type: "static",
												},
											],
										},
									},
								},
							],
						},
					},
				],
			},
		},
		ProductGroupDetails: {
			screen_composition: {
				components: [
					{
						type: "form",
						id: "create_product_group_details",
						staticProps: {
							fields: [
								{
									type: "text",
									id: "product_group_name",
									placeholder: "Name",
									variant: "outlined",
									isMandatory: true,
									label: "Product Group Name",
									showLabel: true,
								},
								{
									type: "text",
									id: "product_group_description",
									placeholder: "Description",
									variant: "outlined",
									isMandatory: true,
									label: "Description",
									showLabel: true,
								},
							],
						},
					},
				],
			},
		},
	},
};
